import * as React from 'react';
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/css';
import { MainContainer, Text, EmptyCart } from '~/components';
import { useStore } from '~/provider/StoreProvider';
import { formatPrice } from '~/utils/stringHelper';
import { PlusCircle, MinusCircle, XCircle, Gift } from 'react-feather';
import StoreActionButton from '~/components/StoreActionButton';
import { emitRefreshEvent, REFRESH_KEY } from '~/service/events/refreshSubject';

const TableRow = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(3),
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.primary.green,
  borderBottomStyle: 'solid',
  marginBottom: theme.spacing(3),
}));

const TableCell = styled.div(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&:last-of-type': {
    paddingTop: theme.spacing(2),
  },
}));

function MobileCheckoutTable({ fields, lineItems, total, className }) {
  function renderTable() {
    return lineItems.map((lineItem, index) => (
      <div>
        <TableRow key={lineItem.id}>
          <TableCell>
            {fields.product(lineItem)}
            {fields.remove(lineItem)}
          </TableCell>
          <TableCell>
            {fields.quantity(lineItem)}
            <Text text={fields.sum(lineItem)} />
          </TableCell>
        </TableRow>
      </div>
    ));
  }

  return (
    <div {...{ className }}>
      {renderTable()}
      <TableCell>
        <Text variant="h3" text="Total" />
        <Text variant="h3" text={total} />
      </TableCell>
    </div>
  );
}

export default MobileCheckoutTable;
