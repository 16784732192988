import * as React from 'react';
import styled from '@emotion/styled';
import Text from './Text';
import Button from './buttons/Button';
import useLockBodyScroll from '~/hooks/useLockBodyScroll';
import { css } from '@emotion/react';

const AGE_GATEWAY = {
  ALLOWED: 'age_gateway_allowed',
  ASKED_AT: 'age_gateway_ask_date',
};

const Container = styled.div(({ theme, show }) => ({
  position: 'fixed',
  zIndex: 3000,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: `${theme.colors.green}90`,
  padding: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
}));

const Content = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.sand,
  color: theme.colors.green,
  padding: theme.spacing(5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const ButtonRow = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing(-2),
  flexDirection: 'row',
  flex: 1,
  paddingTop: theme.spacing(3),
  alignSelf: 'stretch',
}));

const button = (theme) =>
  css({
    marginLeft: theme.spacing(2),
  });

function withAgeGate(WrappedComponent) {
  return (props) => {
    const [showAgeGateway, setShowAgeGateway] = React.useState<boolean>(false);
    useLockBodyScroll(showAgeGateway);

    const ageGatePromise = React.useRef(null);
    const promiseHandlers = React.useRef(null);

    const showAgeGate = React.useCallback(() => {
      try {
        const ageAllowed = localStorage.getItem(AGE_GATEWAY.ALLOWED);

        if (ageAllowed) {
          return Promise.resolve();
        }
        ageGatePromise.current = new Promise<User>((resolve, reject) => {
          promiseHandlers.current = {
            resolve,
            reject,
          };
          setShowAgeGateway(true);
        });
        return ageGatePromise.current;
      } catch (e) {
        return Promise.reject();
      }
    });

    const handleAccept = React.useCallback(() => {
      const ageAllowed = localStorage.setItem(AGE_GATEWAY.ALLOWED, true);
      setShowAgeGateway(false);
      const resolveHandler = promiseHandlers.current?.resolve;
      if (typeof resolveHandler === 'function') {
        resolveHandler();
      }
      ageGatePromise.current = null;
    }, []);

    const handleReject = React.useCallback(() => {
      setShowAgeGateway(false);
      const rejectHandler = promiseHandlers.current?.reject;
      if (typeof resolveHandler === 'function') {
        rejectHandler();
      }
      ageGatePromise.current = null;
    }, []);

    function renderAgeGate() {
      return (
        <Container>
          <Content>
            <h1 css={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
              Bisch älter wie 18?
            </h1>
            <ButtonRow>
              <Button
                css={button}
                variant="sand"
                text={'Nanig'}
                onClick={handleReject}
              />
              <Button
                css={button}
                variant="green"
                text={'Ja'}
                onClick={handleAccept}
              />
            </ButtonRow>
          </Content>
        </Container>
      );
    }

    return (
      <>
        <WrappedComponent showAgeGate={showAgeGate} {...props} />
        {showAgeGateway && renderAgeGate()}
      </>
    );
  };
}

export default withAgeGate;
