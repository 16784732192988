import * as React from 'react';
import isBrowser from '~/utils/isBrowser';

function useMedia(query) {
  const mediaQuery = isBrowser && window.matchMedia(query);

  function getValue() {
    return mediaQuery.matches;
  }

  const [value, setValue] = React.useState(false);

  React.useEffect(() => {
    const handler = () => setValue(getValue);
    mediaQuery.addListener(handler);
    handler();
    return () => mediaQuery.removeListener(handler);
  }, []);

  return value;
}

export default useMedia;
