import firebase from './firebase';
export const EVENT_NAME = firebase?.analytics?.EventName || {};

function logEvent(eventName, eventParams) {
  firebase.analytics().logEvent(eventName, eventParams);
}

export default {
  logEvent,
};
